/* eslint-disable jsx-a11y/iframe-has-title */
// import React from "react";
import "../Footer/Footer.css";
import React, { useEffect, useRef, useState } from "react";
// import { library } from "@fortawesome/fontawesome-svg-core";
// import { fas } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// library.add(fas);
const Footer = () => {
  // const location = useLocation();
  // const [headerText, setHeaderText] = useState(location.pathname);

  // useEffect(() => {
  //   setHeaderText(location.pathname);
  // });
  // const useLocation = () =>{

  // }
  return (
    <>
      <section className="main mt-5">
        {/* footer section start */}
        <section className="footer-section">
          <footer className=" text-map-footer ">
            {/* text-map-footer section start */}
            <div className="row text-center me-0 ns-0">
              <div className="box col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 p-0">
                <div className="text-holder">
                  <h1>Contact</h1>
                  <div className="underline"></div>
                  <div className="info">
                    <h6>Address</h6>
                    <div className="dashed"></div>
                    <p>District Pune , Bavdhan 411031</p>
                  </div>
                  <div className="info">
                    <h6>Phone Number</h6>
                    <div className="dashed"></div>
                    <p>9607755557</p>
                    <p>9730822211</p>
                    <p>8149056660</p>
                  </div>
                  <div className="info">
                    <h6>Email Address</h6>
                    <div className="dashed"></div>
                    <p>info@yourlinks.com</p>
                  </div>
                </div>
              </div>
              {/* map section start */}
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 p-0">
                <div className="map-section">
                  <div className="mapouter">
                    <div className="gmap_canvas">
                      <iframe
                        class="gmap_iframe"
                        frameborder="0"
                        scrolling="no"
                        marginheight="0"
                        marginwidth="0"
                        src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=2nd Floor, Office No 3, M No. 734, Opp High Class Society, Pashan Rd, near Suryadutta College, Patil Nagar, Bavdhan, Pune, Maharashtra 411021&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                      ></iframe>
                      <a href="https://connectionsgame.org/">
                        Connections Puzzle
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* map section end */}
            </div>

            {/* text-map-footer section end */}

            {/* bottom footer start */}
            <div className="bottom-footer text-center">
              <span>
                “Hey Guys! This is a must-be-place. No stress, no trouble. Only
                enjoy the old town!!”
              </span>
            </div>
            {/* bottom footer end */}
          </footer>
        </section>
        {/* footer section end */}
      </section>
    </>
  );
};
export default Footer;
