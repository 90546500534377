import React, { useEffect, useRef, useState, useContext } from "react";
import "../Header1/Header1.css";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import LazyLoad from "react-lazyload";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Context } from "../../utils/context";
import Verified from "../AboutUs/animation/json/Verified";

library.add(fas);

function Header() {
  const ref = useRef();
  const location = useLocation();

  // Load menu state from localStorage or default to false
  const [isMenuOpen, setIsMenuOpen] = useState(() => {
    const storedValue = localStorage.getItem("isMenuOpen");
    return storedValue ? JSON.parse(storedValue) : false;
  });

  const toggleMenu = () => {
    setIsMenuOpen((prevIsMenuOpen) => {
      const newIsMenuOpen = !prevIsMenuOpen;
      localStorage.setItem("isMenuOpen", JSON.stringify(newIsMenuOpen));
      return newIsMenuOpen;
    });
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
    localStorage.setItem("isMenuOpen", JSON.stringify(false));
  };

  const checkIfClickedOutside = (e) => {
    if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
      toggleMenu();
    }
  };

  const { postData } = useContext(Context);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    massage: "",
  });

  const [validationMessages, setValidationMessages] = useState({
    name: "",
    email: "",
    massage: "",
  });

  const [modalShow, setModalShow] = React.useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      // If validation fails, return early
      return;
    }

    try {
      const response = await postData("/without-login/contact", formData);

      if (response?.success) {
        setModalShow({ code: response.code, message: response.message });
        setTimeout(() => {
          window.location = "/";
          setModalShow(false);
        }, 3000);
      } else {
        console.error("Submission failed:", response?.error);
        // Handle submission failure
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      // Handle submission error
    }
  };

  const validateForm = () => {
    let isValid = true;
    const newValidationMessages = {};

    // Perform validation checks and update validation messages
    if (!formData.name) {
      newValidationMessages.name = "Name is required";
      isValid = false;
    }

    // Validate email
    if (!formData.email) {
      newValidationMessages.email = "Email is required";
      isValid = false;
    } else if (!validateEmail(formData.email)) {
      newValidationMessages.email = "Invalid email format";
      isValid = false;
    }

    if (!formData.massage) {
      newValidationMessages.massage = "Message is required";
      isValid = false;
    }

    setValidationMessages(newValidationMessages);
    return isValid;
  };

  const validateEmail = (email) => {
    const emailRegex =
      /^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z]{2,4})\.\2)([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    return emailRegex.test(email);
  };

  useEffect(() => {
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isMenuOpen]);

  const isActive = (path) => {
    return location.pathname === path ? "active" : "";
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <section className="main sticky-top">
      <section className="header">
        <div className="wrapper">
          <nav className="navbar navbar-expand-lg bg-body-tertiary bg-white">
            <div className="container-fluid">
              <Link to="/" className="navbar-brand">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/Images/logo/hostel_logo.png"
                  }
                  className="hostel-logo"
                  alt="..."
                />
              </Link>
              {isMenuOpen ? (
                <>
                  <button
                    type="button"
                    className={`navbar-toggler ${
                      isMenuOpen ? "collapsed" : ""
                    }`}
                    aria-label="Toggle navigation"
                    onClick={toggleMenu}
                  >
                    <span className="navbar-toggler-icon"></span>
                  </button>
                </>
              ) : (
                <button
                  className={`navbar-toggler ${isMenuOpen ? "collapsed" : ""}`}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded={isMenuOpen ? "true" : "false"}
                  aria-label="Toggle navigation"
                  onClick={toggleMenu}
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
              )}

              <div
                className={`collapse navbar-collapse ${
                  isMenuOpen ? "show" : ""
                }`}
                id="navbarSupportedContent"
                ref={ref}
              >
                <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                  <li
                    className={`nav-item ${isActive("/")}`}
                    onClick={closeMenu}
                  >
                    <Link to="/" className="nav-link " aria-current="page">
                      Home
                    </Link>
                  </li>
                  <li
                    className={`nav-item ${isActive("/rooms")}`}
                    onClick={closeMenu}
                  >
                    <Link to="/rooms" className="nav-link">
                      Rooms
                    </Link>
                  </li>
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle "
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="true"
                    >
                      Features
                      <button class="button">
                        <FontAwesomeIcon icon="fa-solid fa-angle-down" />
                      </button>
                    </a>

                    <ul className="dropdown-menu">
                      <li onClick={() => setIsMenuOpen(false)}>
                        <Link to="/aboutus" className="dropdown-item" href="#">
                          About Us
                        </Link>
                      </li>
                      <li onClick={() => setIsMenuOpen(false)}>
                        <Link to="/Gallery" className="dropdown-item" href="#">
                          Gallery
                        </Link>
                      </li>
                      <li onClick={() => setIsMenuOpen(false)}>
                        <Link
                          to="/PrivacyPolicy"
                          className="dropdown-item"
                          href="#"
                        >
                          Privacy Policy
                        </Link>
                      </li>

                      {/* <li onClick={() => setIsMenuOpen(false)}>
                        <Link className="dropdown-item" href="#">
                          Coming Soon
                        </Link>
                      </li> */}
                    </ul>
                  </li>
                  <li
                    className={`nav-item ${isActive("/blog")}`}
                    onClick={closeMenu}
                  >
                    <Link to="/blog" className="nav-link">
                      Blog
                    </Link>
                  </li>
                  <li
                    className={`nav-item ${isActive("/contact")}`}
                    onClick={closeMenu}
                  >
                    <Link
                      onClick={handleShow}
                      type="button"
                      to="#"
                      className="nav-link"
                    >
                      Contact
                    </Link>
                  </li>
                </ul>

                {/* <div className="call">
                  <a>
                    <FontAwesomeIcon
                      icon="fa-solid fa-mobile-screen"
                      className="me-1"
                    />
                    <span>Call Us: (+123) 666 456 789</span>
                  </a>
                </div> */}

                <form className="d-flex" role="search">
                  <Link
                    to="/hostel_form/:id"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    <button className="btn" type="submit">
                      BOOK NOW
                    </button>
                  </Link>
                </form>
              </div>
            </div>
          </nav>
        </div>
      </section>

      <Modal
        className="conttt-mod-sec"
        show={show}
        onHide={handleClose}
        animation={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Get in Touch</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <form>
              <div className=" ">
                <label for="Name" classname="form-label"></label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter your name*"
                  onChange={(e) =>
                    setFormData({ ...formData, name: e.target.value })
                  }
                />
                <p className="text-danger">{validationMessages.name}</p>
              </div>
              <div className=" ">
                <label htmlFor="inputEmail4" className="form-label"></label>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Enter your E-mail*"
                  id="email"
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                />
                <p className="text-danger">{validationMessages.email}</p>
              </div>
              <div className=" ">
                <label for="message" classname="form-label"></label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter your message*"
                  onChange={(e) =>
                    setFormData({ ...formData, massage: e.target.value })
                  }
                />
                <p className="text-danger">{validationMessages.massage}</p>
              </div>
              <div className="text-center">
                <button
                  type="button"
                  className="btn btn-submit"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>

          <div className="row justify-content-center">
            <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-4">
              <div className="submit">
                <div className="contact-modal">
                  <Modal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    centered
                    className="Home-PopUp"
                  >
                    <Modal.Body>
                      <Verified />
                      <h1 className="successfully">
                        Thank You For Your Contact.{" "}
                      </h1>
                      <h1 className="Thanks">Thank You!</h1>
                    </Modal.Body>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </section>
  );
}

export default Header;
