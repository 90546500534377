import React, { useEffect, useState } from "react";
import "./App.css";
import { Route, Routes, useLocation } from "react-router-dom";
import HomePage from "./components/HomePage/HomePage"
//  import Header from './components/Header/Header';
import Footer from './components/Footer/Footer'
import HostelForm1 from './components/HostelForm/HostelForm1';
import Rooms from './components/Rooms/Rooms';
import Blog from './components/Blog/Blog';
import RoomsAllDetails from './components/RoomsAllDetails/RoomsAllDetails';
import BlogAllDetails from './components/BlogAllDetails/BlogAllDetails';

import Contact from "./components/Contact/Contact";
import BlogFeed from "./components/HomePage/BlogFeed/BlogFeed";
import AboutUs from "./components/AboutUs/AboutUs";
import ReadMore from "./components/ReadMore/ReadMore"
import Header1 from "./components/Header1/Header1";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy"
import Gallery from "./components/Gallery/Gallery"

const App = () => {
  const location = useLocation();
  const [headerText, setHeaderText] = useState(location.pathname);

  useEffect(() => {
    setHeaderText(location.pathname);
  });
  // console.log(headerText);
  return (
    <>
      <Header1 />
      <Routes>
        <Route path="/" element={<HomePage />} />
        {/* <Route path="/hostel_form" element={<HostelForm1 />} /> */}
        <Route path="/hostel_form/:id" element={<HostelForm1 />} />
        <Route path="/rooms" element={<Rooms />} />
        <Route path="/roomsAllDetails/:id" element={<RoomsAllDetails />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blogAllDetails/:id" element={<BlogAllDetails />} />
        {/* <Route path="/blogAllDetails/:id" component={<BlogAllDetails/>} /> */}

        <Route path="/contact" element={<Contact />} />
        <Route path="/blogfeed" element={<BlogFeed />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/readmore/:id" element={<ReadMore />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/gallery" element={<Gallery />} />


      </Routes>
      {
        headerText === "/contact" ? <></> : <Footer />
      }

    </>
  );
};

export default App;
